$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;
$black: #001323;
$cyan: #41ead4;
$purple: #5541ea;
$mono: "Sohne Mono Buch";
$mono-bold: "Sohne Mono Halbfett";

body {
  font-family: $mono, monospace;
}

button:focus {
  outline: 1px dotted;
}
label {
  display: block;
}

.nav {
  display: flex;
  align-items: center;
  height: 5rem;
  font-family: $mono;
  border-bottom: 1px solid $black;
  padding: 0 2rem;
  a {
    color: $black;
    &:hover {
      color: $black;
    }
  }
}
.nav-links {
  display: flex;
  padding: 0;
  margin: 0;
  margin-left: 1rem;
  list-style: none;
}
.nav-link a {
  padding: 1rem;
}
.nav-cta {
  margin-left: auto;
  display: inline-block;
  padding: 0.5em 1em;
  border: 2px solid $purple;
  border-radius: 0px;
  background-color: transparent;
  transition: color 200ms ease-out, background-color 200ms ease-out;
  font-family: $mono-bold, monospace;
  color: $purple !important;
  text-decoration: none;
}
.subnav {
  padding: 0.5rem 2rem;
  background: $cyan;
}
.breadcrumbs {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: -2px;
  span {
    padding: 0 0.5rem;
  }
}

.app {
  display: flex;
  border-bottom: 4px solid $purple;
}

.aside {
  position: absolute;
  z-index: 1;
  background: $black;
  transform: translateX(-100%);
  padding: 0 1rem;
  transition: transform 250ms ease-out;
  &.is-active {
    transform: translateX(0);
  }
  @media only screen and (min-width: $lg) {
    position: relative;
    flex: 0 0 16rem;
    padding: 0 2rem;
    transform: none;
  }
}
.aside-button {
  display: inline-block;
  margin: 1rem;
  padding: 0.5em 1em;
  border: 2px solid $purple;
  border-radius: 0px;
  background-color: transparent;
  transition: color 200ms ease-out, background-color 200ms ease-out;
  font-family: $mono-bold, monospace;
  color: $purple !important;
  @media only screen and (min-width: $lg) {
    display: none;
  }
}
.aside .aside-button {
  margin: 1rem 0;
}

.main {
  position: sticky;
  top: 0;
  align-self: flex-start;
  overflow: hidden;
}

.title {
  font-family: $mono-bold;
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: white;
}

.subtitle {
  font-family: $mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: white;
  margin: 1rem 0 1rem 0;
}
.subtitle--sm {
  font-size: 10px;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin: 1rem 0 0.5rem 0;
}

.sidebar__section {
  padding: 1rem 0;
  @media only screen and (min-width: $lg) {
    padding: 2rem 0;
  }
}

.sidebar__group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    flex: 0 0 45%;
  }
}

/* These styles are for the toggles */
.toggle-group {
  vertical-align: center;
  font-family: $mono-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.toggle {
  text-align: center;
  display: inline-block;
  border: 1px solid $cyan;
  width: 49.5%;
  height: 45px;
  box-sizing: border-box;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
}
.toggle--sm {
  height: 24px;
  width: 50%;
  font-size: 12px;
}

.toggle--active {
  box-sizing: border-box;
  background: $cyan;
  color: rgba(255, 255, 255, 1);
  border: 1px solid $cyan;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

/*These styles are for the dropdown inputs*/
.dropdown,
.dropdown--red {
  font-family: $mono-bold, monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 1px;
  padding: 10px 8px;
  background: white;
  height: 45px;
  border: none;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
}

.dropdown:focus {
  box-shadow: 0 0 1px 2px #40ead3;
  outline: none;
}

.dropdown--red {
  box-shadow: 0 0 1px 2px red;
  outline: none;
}

.dropdown__mm {
  width: 98%;
}

.dropdown__feet,
.dropdown__inch {
  box-sizing: border-box;
  margin-right: 6px;
  flex: 0 0 30%;
  width: 30%;
  background-image: url("../images/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
}

.dropdown__fraction {
  box-sizing: border-box;
  flex: 1 1 40%;
  width: 32%;
  background-image: url("../images/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-color: rgba(255, 255, 255, 0.4);
}

.dropdown:hover {
  cursor: pointer;
}

.stairangle__number {
  background: white;
  padding: 11px auto;
  border-radius: 3px;
  text-align: center;
  width: 40%;
}

/*Slider controls */

.slider__height {
  width: 100%;
}

.slider__angle {
  width: 55%;
  margin: auto 0;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  height: 2px; /* Specified height */
  background: rgba(255, 255, 255, 0.5); /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 8px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #41ead4; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 8px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #41ead4; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

/* Scrollbar style */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 1em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content {
  padding: 2rem 0 4rem;
  max-width: 50rem;
  margin: 0 1rem;
  font-family: "Söhne Buch";
  font-size: 1rem;
  h1 {
    font-family: "Pilat";
  }
  dt {
    font-family: "Söhne Halbfett";
  }
  a {
    color: $purple;
    &:hover {
      color: $purple;
    }
  }
  @media only screen and (min-width: $lg) {
    font-size: 1.25rem;
    margin: 0 auto;
    padding: 4rem 0;
  }
}
